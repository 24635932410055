//var wow = new WOW();
//wow.init();

var $j = jQuery.noConflict();
var $scroll = 0;
var sticky_amount = 0;
var scroll_amount_for_sticky = 150;
var logo_width = 283;
var logo_height = 35;
var header_height = 85;
var min_header_height_sticky = 60;
var $window = $j(window),carousel_flexslider,portfolio_flexslider;
var parallax_speed = 0.5;

function getFullWidthSize() {
	return  (window.innerWidth <= 480) ? 1 :
			(window.innerWidth <= 768) ? 2 :
			(window.innerWidth <= 1024) ? 3 :
			(window.innerWidth <= 1400) ? 4 : 4;
}

function getGridSize() {
	return	(window.innerWidth <= 480) ? 1 : 
			(window.innerWidth <= 1000) ? 3 : 5;
}

function getGridSizeTestimonials() {
	return (window.innerWidth <= 720) ? 1 :
		   (window.innerWidth <= 1000) ? 2 : 3;
}

function getGridSizePartners() {
	return 	(window.innerWidth <= 768) ? 1 :
			(window.innerWidth <= 1024) ? 2 : 5;
}

function getCBoxWidth() {
	return 	(window.innerWidth <= 768) ? '100%' :
			(window.innerWidth <= 1024) ? '80%' : '75%';
}

function getCBoxHeight() {
	return 	(window.innerHeight <= 768) ? '100%' :
			(window.innerWidth <= 1024) ? '80%' : '75%';
}

function ten9_form_validate(element) {
    $('html, body').animate({scrollTop: $(element).offset().top-100}, 150);
    element.effect("highlight", { color: "#F2DEDE" }, 1500);
    element.parent().effect('shake');
}

/*
**	Pie Chart shortcode
*/
function initPieChart(){
	"use strict";
 
	if(jQuery('.q_percentage').length){
		jQuery('.q_percentage').each(function() {

			var $barColor = '#00aeef';

			if(jQuery(this).data('active') !== ""){
				$barColor = jQuery(this).data('active');
			}

			var $trackColor = '#f3f3f3';

			if(jQuery(this).data('noactive') !== ""){
				$trackColor = jQuery(this).data('noactive');
			}

			var $line_width = 10;

			if(jQuery(this).data('linewidth') !== ""){
				$line_width = jQuery(this).data('linewidth');
			}
			
			var $size = 140;
			
			if(jQuery(this).data('size') !== ""){
				$size = jQuery(this).data('size');
			}

			jQuery(this).appear(function() {
				initToCounterPieChart(jQuery(this));
				jQuery(this).parent().css('opacity', '1');
				
				jQuery(this).easyPieChart({
					barColor: $barColor,
					trackColor: $trackColor,
					scaleColor: false,
					lineCap: 'butt',
					lineWidth: $line_width,
					animate: 1500,
					size: $size
				}); 
			},{accX: 0, accY: -200});
		});
	}
}

/*
**	Plugin for counter shortcode
*/
(function($) {
	"use strict";

	$.fn.countTo = function(options) {
		// merge the default plugin settings with the custom options
		options = $.extend({}, $.fn.countTo.defaults, options || {});

		// how many times to update the value, and how much to increment the value on each update
		var loops = Math.ceil(options.speed / options.refreshInterval),
		increment = (options.to - options.from) / loops;

		return $(this).each(function() {
			var _this = this,
			loopCount = 0,
			value = options.from,
			interval = setInterval(updateTimer, options.refreshInterval);

			function updateTimer() {
				value += increment;
				loopCount++;
				$(_this).html(value.toFixed(options.decimals));

				if (typeof(options.onUpdate) === 'function') {
					options.onUpdate.call(_this, value);
				}

				if (loopCount >= loops) {
					clearInterval(interval);
					value = options.to;

					if (typeof(options.onComplete) === 'function') {
						options.onComplete.call(_this, value);
					}
				}
			}
		});
	};

	$.fn.countTo.defaults = {
		from: 0,  // the number the element should start at
		to: 100,  // the number the element should end at
		speed: 1000,  // how long it should take to count between the target numbers
		refreshInterval: 100,  // how often the element should be updated
		decimals: 0,  // the number of decimal places to show
		onUpdate: null,  // callback method for every time the element is updated,
		onComplete: null  // callback method for when the element finishes updating
	};
})(jQuery);

/*
**	Counter for pie chart number from zero to defined number
*/
function initToCounterPieChart($this){
	"use strict";

	jQuery($this).css('opacity', '1');
	var $max = parseFloat(jQuery($this).find('.tocounter').text());
	jQuery($this).find('.tocounter').countTo({
		from: 0,
		to: $max,
		speed: 1500,
		refreshInterval: 50
	});
}

/*
**	Calculating header size on page load and page scroll
*/
var sticky_animate;
function headerSize($scroll){
	"use strict";
	
	sticky_amount = scroll_amount_for_sticky;

	if($scroll > sticky_amount){
		//console.log('$scroll > sticky_amount '+$scroll+' > '+sticky_amount);
		$j('header.header .sticky').addClass('sticky_animate');
	}else{
		//console.log('$scroll < sticky_amount '+$scroll+' < '+sticky_amount);
		$j('header.header .sticky').removeClass('sticky_animate');
	}
}

/*
**	Set content bottom margin because of the uncovering footer
*/
function setContentBottomMargin(){
	if(Foundation.MediaQuery.atLeast('medium')){
		if(!$j('body.error404').length){
			if( $j('body.blog, body.archive, body.search').length ){
				$j('#content').css('margin-bottom', $j('.footer').height() - 1);
			}
			else{
				$j('#content').css('margin-bottom', $j('.footer').height() - header_height - 1);
			}
		}
		else{
			$j('#content').css('margin-bottom', 0);
		}
	}
}

/*
** Init Carousel
*/
function initCarousel(){
	"use strict";

	if($j('.carousels').length){
		$j('.carousels').flexslider({
			animationLoop: true,
			controlNav: false,
			useCSS: true,
			pauseOnAction: true,
			pauseOnHover: true,
			slideshow: true,
			move: 1,
			animation: 'slides',
			itemWidth: 220,
			itemMargin: 2,
			minItems: getGridSize(),
			maxItems: getGridSize(),
			animationSpeed: 1000,
			slideshowSpeed: 5000,
			start: function(slider){
				carousel_flexslider = slider;
			}
		});
	}
	
	if($j('.testimonials_carousel').length){
		$j('.testimonials_carousel').flexslider({
			animationLoop: true,
			controlNav: true,
			useCSS: true,
			pauseOnAction: true,
			pauseOnHover: true,
			slideshow: true,
			animation: 'slides',
			itemWidth: 300,
			itemMargin: 28,
			minItems: getGridSizeTestimonials(),
			maxItems: getGridSizeTestimonials(),
			animationSpeed: 1000,
			slideshowSpeed: 12000,
			start: function(slider){
				carousel_flexslider = slider;
			}
		});
	}
	
/*
	if($j('partners_carousel').length){alert('hi');
		$j('.partners_carousel').flexslider({
			animationLoop: false,
			controlNav: false,
			slideshow: true,
			itemWidth: 300,
			itemMargin: 28,
			minItems: getGridSizePartners(),
			maxItems: getGridSizePartners(),
			start: function(slider){
				carousel_flexslider = slider;
			}
		});
	}
*/
}

/*
** Init Portfolio Slider
*/
function initPortfolioSlider(){
	"use strict";
	
	if($j('.portfolio_slider').length){
		$j('.portfolio_slider').flexslider({
			animationLoop: true,
			controlNav: false,
			useCSS: true,
			pauseOnAction: true,
			pauseOnHover: true,
			slideshow: false,
			selector: ".portfolio_slides > li",
			animation: "slide",
			prevText: "<div><i class='fa fa-angle-left'></i></div>",
			nextText: "<div><i class='fa fa-angle-right'></i></div>",
			itemWidth: 300,
			itemMargin: 0,
			move: 1,
			minItems: getFullWidthSize(),
			maxItems: getFullWidthSize(),
			animationSpeed: 600,
			slideshowSpeed: 8000000,
			start: function(slider){
				portfolio_flexslider = slider;
			}
		});
		
		$j('.portfolio_slider .flex-direction-nav a').click(function(e){
			e.preventDefault();
			e.stopImmediatePropagation();
			e.stopPropagation();
		});
	}
}

/*
**	Init Portfolio list and Portfolio Filter
*/
function initPortfolio(){
	"use strict";
	
	if($j('.projects_holder_outer').length){
		//$j('.projects_holder_outer').each(function(){
			
/*
			$j('.filter_holder.filter_category, .filter_holder.filter_tag').each(function(){
				var filter_height = 0;
				$j(this).find('li.filter').each(function(){
					filter_height += $j(this).height();
				});
				
				$j(this).on('click',function(data){
					var $drop = $j(this),
					$bro = $drop.siblings('.hidden');
					
					if(!$drop.hasClass('expanded')){
						$drop.find('ul').css('z-index','1000');
						$drop.find('ul').height(filter_height+39); //36 is height of first default item + 1 border * 2 + 1 border on li
						$drop.addClass('expanded');
						var label = $drop.find('.label span');
						label.text(label.attr('data-label'));
					} else {
						$drop.find('ul').height(36);
						$drop.removeClass('expanded');
						
						var $selected = $j(data.target),
						ndx = $selected.index();
						
						if($bro.length){
							$bro.find('option').removeAttr('selected').eq(ndx).attr('selected','selected').change();
						}
					}
				});
			});
*/
			
/*
			$j('.filter_holder.filter_category .filter').on('click',function(){
				var $this = $j(this).text();
				var dropLabels = $j('.filter_holder.filter_category').find('.label span');
				dropLabels.each(function(){
					$j(this).text($this);
				});
			});
			$j('.filter_holder.filter_tag .filter').on('click',function(){
				var $this = $j(this).text();
				var dropLabels = $j('.filter_holder.filter_tag').find('.label span');
				dropLabels.each(function(){
					$j(this).text($this);
				});
			});
*/
			
			var initialFilter = 'all';
			var hash = window.location.hash.replace(/^#/g, '');
			console.log(hash);
			if(hash){
				initialFilter = '.' + hash;
			}
			
			var mixer = mixitup($j('.projects_holder'), {
				load: {
					filter: initialFilter
				},
				controls: {
					toggleLogic: 'and'
			    },
			    animation: {
				    duration: 250,
				    nudge: true,
				    reverseOut: false,
				    effects: "fade scale(0.01) translateX(20%) translateY(20%) translateZ(-100px) stagger(30ms)"
				}
			});
/*
			$j(this).find('.projects_holder').mixitup({
				showOnLoad: filter,
				transitionSpeed: 600,
				minHeight: 150,
				onMixEnd: function(){
					
				}
			});
*/
		//});
	}
}

/*
**	Load more portfolios
*/
function loadMore(){
	"use strict";
	
	var i = 1;
	
	$j('.load_more a').on('click', function(e)  {
		e.preventDefault();
		
		var link = $j(this).attr('href');
		var $content = '.projects_holder';
		var $anchor = '.portfolio_paging .load_more a';
		var $next_href = $j($anchor).attr('href'); // Get URL for the next set of posts
		var filler_num = $j('.projects_holder .filler').length;
		$j.get(link+'', function(data){
			$j('.projects_holder .filler').slice(-filler_num).remove();
			var $new_content = $j($content, data).wrapInner('').html(); // Grab just the content
			$next_href = $j($anchor, data).attr('href'); // Get the new href
			$j('article.mix:last').after($new_content); // Append the new content
			
			var min_height = $j('article.mix:first').height();
			$j('article.mix').css('min-height',min_height);
			
			$j('.projects_holder').mixitup('remix','all');
			prettyPhoto();
			if($j('.load_more').attr('rel') > i) {
				$j('.load_more a').attr('href', $next_href); // Change the next URL
			} else {
				$j('.load_more').remove(); 
			}
			$j('.projects_holder .portfolio_paging:last').remove(); // Remove the original navigation
			$j('article.mix').css('min-height',0);
			
		});
		i++;
	});
}

/*
**	Picture popup for portfolio lists and portfolio single 
*/
function prettyPhoto(){
	"use strict";		

	$j('a[data-rel]').each(function() {
		$j(this).attr('rel', $j(this).data('rel'));
	});

	$j("a[rel^='prettyPhoto']").prettyPhoto({
		animation_speed: 'normal', /* fast/slow/normal */
		slideshow: false, /* false OR interval time in ms */
		autoplay_slideshow: false, /* true/false */
		opacity: 0.80, /* Value between 0 and 1 */
		show_title: true, /* true/false */
		allow_resize: true, /* Resize the photos bigger than viewport. true/false */
		default_width: 650,
		default_height: 400,
		counter_separator_label: '/', /* The separator for the gallery counter 1 "of" 2 */
		theme: 'pp_default', /* light_rounded / dark_rounded / light_square / dark_square / facebook */
		hideflash: false, /* Hides all the flash object on a page, set to TRUE if flash appears over prettyPhoto */
		wmode: 'opaque', /* Set the flash wmode attribute */
		autoplay: true, /* Automatically start videos: True/False */
		modal: false, /* If set to true, only the close button will close the window */
		overlay_gallery: false, /* If set to true, a gallery will overlay the fullscreen image on mouse over */
		keyboard_shortcuts: true, /* Set to false if you open forms inside prettyPhoto */
		deeplinking: false,
		social_tools: false
	});
}

$j(document).ready(function(){
	"use strict";
	
	$j('.scrollup').on('click', function (evt) {
		$j("html, body").animate({scrollTop: 0}, 1000);
		evt.preventDefault();
	});
	
	$j('.cfpopup').on('click', function (evt) {
		evt.preventDefault();
	});
	
	initCarousel();
	initPortfolioSlider();
	loadMore();
	//prettyPhoto();
	initPieChart();
	
	$scroll = $j(document).scrollTop();
	if($j(window).width() >= 1024){
		headerSize($scroll);
	}
	
	//if($j('html').hasClass('touch') !== true){
	if (Foundation.MediaQuery.atLeast('large') && $j('.mast-bg').length) {
		$j('.mast-bg').parallaxBackground({
		  'image': '.parallax_section_holder'
		});
	}
	
	$j("[data-paroller-factor]").paroller();
	
	if($j('.article-header').length){
		$j('.article-header').parallaxBackground({
		  'image': '.parallax',
		});
	}
	
	if($j('.article-background-image').length){
		$j('.article-background-image').parallaxBackground({
		  'image': '.parallax'
		});
	}
	
	$j('.inquiry-modal').colorbox({
		iframe			: true,
		width			: getCBoxWidth(),
		height			: getCBoxHeight(),
		overlayClose	: false,
		fixed			: true,
		fastIframe		: false
	});
	
	$j('.popup').colorbox();
	$j('.lightbox_single_portfolio, .lightbox').colorbox({
		rel: 'project',
		innerWidth: '85%'
	});
	$j('.lightbox_single').colorbox({
		innerWidth: '85%'
	});
	
	if( $j(window).width() < 1024 ){
		//$j('.mobile_search_wrap').hide();
		$j('.mobile_search').click(function(){
			$j('.mobile_search_wrap').toggle();
		});
	}
	
	if( $j('.flip').length ){
		$j('.flip').flip({
			axis: 'y',
			trigger: 'hover',
			speed: 1000
		});
	}
});

$j(window).on('load', function() {
	"use strict";
	
	initPortfolio();
	//initPortfolioSingleInfo();
	setContentBottomMargin();
});

$j(window).on('scroll', function() {
	"use strict";

	$scroll = $j(document).scrollTop();
	//console.log($scroll);
	if($j(window).width() >= 1024){
		//console.log('window width >= 1024');
		headerSize($scroll);
	}
	
	if (Foundation.MediaQuery.atLeast('medium')) {
		if($j(document).scrollTop()>200){
			$j('.scrollup').addClass('show');
		} else if(jQuery(document).scrollTop()<150){
			$j('.scrollup').removeClass('show');
		}
	}
});

$j(window).on('resize', function() {
	"use strict";
	
	setContentBottomMargin();
	
	if($j(window).width() > 1024){
		headerSize($scroll);
	}
	
	//if($j('html').hasClass('touch') !== true){
		$j('.mast-bg').parallaxBackground({
		  'image': '.parallax_section_holder'
		});
		
		$j('.article-header').parallaxBackground({
		  'image': '.parallax'
		});
	//}
	
	if($j('.carousels').length){
		var gridSize = getGridSize();
	 
		carousel_flexslider.vars.minItems = gridSize;
		carousel_flexslider.vars.maxItems = gridSize;
	}
	
	if($j('.testimonials_carousel').length){
		var gridSizeT = getGridSizeTestimonials();
	 
		carousel_flexslider.vars.minItems = gridSizeT;
		carousel_flexslider.vars.maxItems = gridSizeT;
	}
	
	if($j('.portfolio_slider').length){
		var fullWidthSize = getFullWidthSize();
	 
		portfolio_flexslider.vars.minItems = fullWidthSize;
		portfolio_flexslider.vars.maxItems = fullWidthSize;
	}
});

Foundation.Abide.defaults.validators['checkbox_limit'] = function(el, required, parent) {
	var group = parent.closest('.checkbox-group');
	var min = group.attr('data-abide-validator-min');
	var checked = group.find(':checked').length;
	if (checked >= min) {
		group.find('span.form-error').hide();
		return true;
	} else {
		group.find('span.form-error').css({
			display: 'block'
		});
		return false;
	}
};

/*
**	Smmoth scrolling
*/
/*
$j(function() {
  $j('a.smooth').click(function() {
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
      var target = $j(this.hash);
      target = target.length ? target : $j('[name=' + this.hash.slice(1) +']');
      if (target.length) {
        $j('html,body').animate({
          scrollTop: target.offset().top - 150
        }, 500);
        return false;
      }
    }
  });
});
*/

jQuery(function() {
	jQuery('a[href*="#"]:not([href="#"]):not(.magellan):not(.tablink):not(.cfpopup)').on('click', function (evt) {
		if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
			var target = jQuery(this.hash);
			target = target.length ? target : jQuery('[id=' + this.hash.slice(1) +']');
			if (target.length) {
				if(Foundation.MediaQuery.atLeast('large')){
					jQuery('html,body').animate({
						scrollTop: (target.offset().top) - 120
					}, 1000);
				}
				else if(Foundation.MediaQuery.atLeast('medium')){
					jQuery('html,body').animate({
						scrollTop: (target.offset().top) - 90
					}, 1000);
				}
				else{
					jQuery('html,body').animate({
						scrollTop: target.offset().top
					}, 1000);
				}
				return false;
				evt.preventDefault();
			}
		}
	});
});